// Google Recaptcha v3
grecaptcha.ready(function () {
	$('form.mod-recaptcha').each(function () {
		var object = $(this);
		// TODO: replace key with project one - this one is a Codename framework key
		grecaptcha.execute('6Lf_p5ggAAAAACUVVZF9NJkxpJPpPbRfKzr55bIl', { action: 'homepage' }).then(function (token) {
			$('<input>').attr({
				type: 'hidden',
				name: 'g-recaptcha-response',
				value: token
			}).appendTo(object)
		});
	});
});

// AOS Initialilse
AOS.init({
	duration: 500,
	offset: 5,
	startEvent: 'load',
	disable: 'mobile'
});

AOS.refresh();

function hasTouch() {
	return 'ontouchstart' in document.documentElement
		|| navigator.maxTouchPoints > 0
		|| navigator.msMaxTouchPoints > 0;
}

// Hack to remove all hover effects on touch devices
if (hasTouch()) { // remove all :hover stylesheets
	try { // prevent exception on browsers not supporting DOM styleSheets properly
		for (var si in document.styleSheets) {
			var styleSheet = document.styleSheets[si];
			if (!styleSheet.rules) continue;

			for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
				if (!styleSheet.rules[ri].selectorText) continue;

				if (styleSheet.rules[ri].selectorText.match('btn:hover')) {
					styleSheet.deleteRule(ri);
				}
			}
		}
	} catch (ex) { }
}

// Mobile hamburger menu
$('.mob-hamburger').click(function (e) {
	e.preventDefault();

	$(this).toggleClass('mod-active');
	$('#nav').toggleClass('mod-active');
	// $('.logo').fadeToggle();
});

$('#nav ul.list-nav > li a[href^="#"]').on('click', function (e) {
	const $this = $(this);

	$this.unbind('mouseout');
	$this.toggleClass('mod-dropdown-active');
	$this.next('.sub-dropdown-wrap').toggleClass('mod-active');

	e.preventDefault();
});

// Show/hide cookie policy
$("body").on("click", ".section-cookies .btn-accept", function (e) {
	e.preventDefault();

	// If no validation errors
	$.ajax({
		method: "POST",
		url: "api/cookies/",
	}).done(function (response) {
		$(".section-cookies").slideToggle();
	});
});

// Cookies banner
$(".section-cookies .btn-decline").on("click", (ev) => {
	ev.preventDefault();

	$(".section-cookies").slideToggle();
});

// Pops out share modal window
$(".social-share").click(function (e) {
	// Prevents default action
	e.preventDefault();
	// Runs window popup function
	windowPopup($(this).attr("href"), 500, 300);
});

// Ajax contact form processing
$('#contact form').submit(function (e) {
	e.preventDefault();

	$.ajax({
		method: 'POST',
		url: 'process/contact/',
		data: $(this).serialize()
	}).done(function (response) {

		// If no errors
		if (response == 'success') {
			// Reset form completely
			$('#contact form .form-element').removeClass('mod-filled');
			$('#contact form').hide().trigger('reset');

			// Show thank you message
			$('#contact .wrap-thanks').fadeIn();

			// Remove any errors that may still be there
			$('#contact form .sub-errors').html('');

			// Hide thank you message after 10 seconds and return to form
			setTimeout(function () {
				$('#contact form').fadeIn();
				$('#contact .wrap-thanks').hide();
			}, 10000);

		}
		else {
			$('#contact form .sub-errors').fadeIn().html(response);
		}
	});
});

// Add class to inputs when clicked on add effect

if ($('form').length) {
	$('form input, form textarea').focus(function () {
		$(this).parent().addClass('mod-filled');

	}).blur(function () {
		if ($(this).val()) {
			$(this).parent().addClass('mod-filled');
		} else {
			$(this).parent().removeClass('mod-filled');
		}
	});
}


// Google Maps
// If div with ID of map exists then run the function
if ($('#map').length) {
	function createMap(el, lat, long, zoom, marker) {
		// Set a center point of the map, can be re-evaluated using `centerMap()`
		const center = { lat: lat, lng: long };

		const mapOptions = {
			zoom: zoom,
			center: center,
			fullscreenControl: false,
		};

		// Create a map instance.
		const melonMap = new MelonMap(document.getElementById(el), mapOptions);

		// Marker locations [name, lat, long, zindex]
		const markerLocation = [['Kingerlee Homes', lat, long, 1]];

		if (marker == true) {
			markerLocation.forEach((markerLocation) => {
				const title = markerLocation[0];

				const location = {
					lat: parseFloat(markerLocation[1]),
					lng: parseFloat(markerLocation[2])
				}

				melonMap.addMarker(location, title);
			});
		}

		// Add a map style
		melonMap.setMapStyle(
			[
				{
					"featureType": "all",
					"elementType": "geometry.fill",
					"stylers": [
						{
							"weight": "2.00"
						}
					]
				},
				{
					"featureType": "all",
					"elementType": "geometry.stroke",
					"stylers": [
						{
							"color": "#9c9c9c"
						}
					]
				},
				{
					"featureType": "all",
					"elementType": "labels.text",
					"stylers": [
						{
							"visibility": "on"
						}
					]
				},
				{
					"featureType": "landscape",
					"elementType": "all",
					"stylers": [
						{
							"color": "#f2f2f2"
						}
					]
				},
				{
					"featureType": "landscape",
					"elementType": "geometry.fill",
					"stylers": [
						{
							"color": "#ffffff"
						}
					]
				},
				{
					"featureType": "landscape.man_made",
					"elementType": "geometry.fill",
					"stylers": [
						{
							"color": "#ffffff"
						}
					]
				},
				{
					"featureType": "poi",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "road",
					"elementType": "all",
					"stylers": [
						{
							"saturation": -100
						},
						{
							"lightness": 45
						}
					]
				},
				{
					"featureType": "road",
					"elementType": "geometry.fill",
					"stylers": [
						{
							"color": "#eeeeee"
						}
					]
				},
				{
					"featureType": "road",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#7b7b7b"
						}
					]
				},
				{
					"featureType": "road",
					"elementType": "labels.text.stroke",
					"stylers": [
						{
							"color": "#ffffff"
						}
					]
				},
				{
					"featureType": "road.highway",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "simplified"
						}
					]
				},
				{
					"featureType": "road.arterial",
					"elementType": "labels.icon",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "transit",
					"elementType": "all",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "water",
					"elementType": "all",
					"stylers": [
						{
							"color": "#46bcec"
						},
						{
							"visibility": "on"
						}
					]
				},
				{
					"featureType": "water",
					"elementType": "geometry.fill",
					"stylers": [
						{
							"color": "#c8d7d4"
						}
					]
				},
				{
					"featureType": "water",
					"elementType": "labels.text.fill",
					"stylers": [
						{
							"color": "#070707"
						}
					]
				},
				{
					"featureType": "water",
					"elementType": "labels.text.stroke",
					"stylers": [
						{
							"color": "#ffffff"
						}
					]
				}
			],
			{ name: 'Styled Map' }
		);
	}

	// If the contact page then create the map with the following coordiantes
	if ($('#contact').length) {
		createMap('map', 51.827143587519835, -1.3021698018200336, 13, true);
	}
}

// Function for popout window
function windowPopup(url, width, height) {
	// Calculate the position for the popup so it’s centered on the screen.
	const left = (screen.width / 2) - (width / 2);
	const top = (screen.height / 2) - (height / 2);

	// opens a window with the given url & set position from above
	window.open(url, "", "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=" + width + ",height=" + height + ",top=" + top + ",left=" + left);
}

// Ensure the function exists on the page before we call it.
if (!!window['gdprSubscribeModal']) {
	// The form of which to attach the modal to.
	const formSelector = '#contact-form';
	// The url to link to for the privacy policy.
	const privacyPolicyURL = 'privacy-policy.html';
	// Color of the links in the modal
	const linkColour = '#000';
	// Color of the buttons in the modal.
	const buttonTextColour = '#000';

	// Call this per form.
	gdprSubscribeModal(formSelector, privacyPolicyURL, linkColour, buttonTextColour);
}

$(document).ready(function () {
	// Initialize HOME page HERO IMAGES slider
	$('.list-home-hero-images').slick({
		arrows: false,
		autoplay: true,
		autoplaySpeed: 8000,
		dots: false,
		fade: true,
		cssEase: 'linear',
		pauseOnHover: false,
		infinite: true,
		slidesToScroll: 1,
		slidesToShow: 1,
		speed: 1500,
		variableWidth: false,
	});

	// Initialize HOME page TESTIMONIALS slider
	$('.list-testimonials').slick({
		arrows: false,
		asNavFor: '.list-testimonial-images',
		autoplay: true,
		autoplaySpeed: 12000,
		dots: true,
		infinite: true,
		pauseOnHover: false,
		slidesToScroll: 1,
		slidesToShow: 1,
		speed: 500,
		variableWidth: false,
	});

	// Initialize HOME page TESTIMONIALS Images slider
	$('.list-testimonial-images').slick({
		arrows: false,
		asNavFor: '.list-testimonials',
		autoplay: true,
		autoplaySpeed: 12000,
		cssEase: 'linear',
		dots: false,
		fade: true,
		infinite: true,
		pauseOnHover: false,
		slidesToScroll: 1,
		slidesToShow: 1,
		speed: 500,
		variableWidth: false,
	});

	// Initialize DEVELOPMENT page HERO IMAGES slider
	$('.list-development-hero-images').slick({
		arrows: false,
		autoplay: true,
		autoplaySpeed: 8000,
		dots: true,
		fade: true,
		cssEase: 'linear',
		pauseOnHover: false,
		infinite: true,
		slidesToScroll: 1,
		slidesToShow: 1,
		speed: 1500,
		variableWidth: false,
	});

	// Initialize DEVELOPMENT page MOBILE GALLERY slider
	$('.list-gallery-images--mobile').slick({
		arrows: false,
		centerMode: true,
		centerPadding: '60px',
		dots: false,
		slidesToShow: 1,
	});


		// Initialize DEVELOPMENT page HERO IMAGES slider
		$('.list-gallery-lightbox-images').slick({
			arrows: true,
			autoplay: false,
			cssEase: 'linear',
			pauseOnHover: false,
			infinite: true,
			slidesToScroll: 1,
			slidesToShow: 1,
			speed: 1500,
			variableWidth: false,
		});

	if ($('#developmentmap').length) {
		function createMap(el, lat, long, zoom, marker) {
			// Set a center point of the map, can be re-evaluated using `centerMap()`
			const center = { lat: lat, lng: long };

			const mapOptions = {
				zoom: zoom,
				center: center,
				fullscreenControl: false,
			};

			// Create a map instance.
			const melonMap = new MelonMap(document.getElementById(el), mapOptions);

			// Marker locations [name, lat, long, zindex]
			const markerLocation = [['Kingerlee Homes', lat, long, 1]];

			if (marker == true) {
				markerLocation.forEach((markerLocation) => {
					const title = markerLocation[0];

					const location = {
						lat: parseFloat(markerLocation[1]),
						lng: parseFloat(markerLocation[2])
					}

					melonMap.addMarker(location, title);
				});
			}

			// Add a map style
			melonMap.setMapStyle(
				[
					{
						"featureType": "all",
						"elementType": "geometry.fill",
						"stylers": [
							{
								"weight": "2.00"
							}
						]
					},
					{
						"featureType": "all",
						"elementType": "geometry.stroke",
						"stylers": [
							{
								"color": "#9c9c9c"
							}
						]
					},
					{
						"featureType": "all",
						"elementType": "labels.text",
						"stylers": [
							{
								"visibility": "on"
							}
						]
					},
					{
						"featureType": "landscape",
						"elementType": "all",
						"stylers": [
							{
								"color": "#f2f2f2"
							}
						]
					},
					{
						"featureType": "landscape",
						"elementType": "geometry.fill",
						"stylers": [
							{
								"color": "#ffffff"
							}
						]
					},
					{
						"featureType": "landscape.man_made",
						"elementType": "geometry.fill",
						"stylers": [
							{
								"color": "#ffffff"
							}
						]
					},
					{
						"featureType": "poi",
						"elementType": "all",
						"stylers": [
							{
								"visibility": "off"
							}
						]
					},
					{
						"featureType": "road",
						"elementType": "all",
						"stylers": [
							{
								"saturation": -100
							},
							{
								"lightness": 45
							}
						]
					},
					{
						"featureType": "road",
						"elementType": "geometry.fill",
						"stylers": [
							{
								"color": "#eeeeee"
							}
						]
					},
					{
						"featureType": "road",
						"elementType": "labels.text.fill",
						"stylers": [
							{
								"color": "#7b7b7b"
							}
						]
					},
					{
						"featureType": "road",
						"elementType": "labels.text.stroke",
						"stylers": [
							{
								"color": "#ffffff"
							}
						]
					},
					{
						"featureType": "road.highway",
						"elementType": "all",
						"stylers": [
							{
								"visibility": "simplified"
							}
						]
					},
					{
						"featureType": "road.arterial",
						"elementType": "labels.icon",
						"stylers": [
							{
								"visibility": "off"
							}
						]
					},
					{
						"featureType": "transit",
						"elementType": "all",
						"stylers": [
							{
								"visibility": "off"
							}
						]
					},
					{
						"featureType": "water",
						"elementType": "all",
						"stylers": [
							{
								"color": "#46bcec"
							},
							{
								"visibility": "on"
							}
						]
					},
					{
						"featureType": "water",
						"elementType": "geometry.fill",
						"stylers": [
							{
								"color": "#c8d7d4"
							}
						]
					},
					{
						"featureType": "water",
						"elementType": "labels.text.fill",
						"stylers": [
							{
								"color": "#070707"
							}
						]
					},
					{
						"featureType": "water",
						"elementType": "labels.text.stroke",
						"stylers": [
							{
								"color": "#ffffff"
							}
						]
					}
				],
				{ name: 'Styled Map' }
			)
		}

		if ($('#developmentmap').length) {
			createMap('developmentmap', parseFloat(document.getElementById('lat').value), parseFloat(document.getElementById('long').value), 13, true);
		}
	}
});